import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { StaticTemplate } from '../../../templates/staticTemplate'
import { Layout } from '../../../components/layouts/layout'
import { PageMeta } from '../../../components/page-meta'
import { CalculatorSectionModule } from '../../../components/modules/CalculatorSectionModule'
import { AutoAffordCalculator } from '../../../components/calculators'
import { anchorLinkSections, CrossSellTout } from './constants'
import { usePages } from '../constants'

const AutoAffordPage = ({ pageContext, location }) => {
  const [t] = useTranslation()
  const { calculatorsPage, autoPage } = usePages()

  const pageData = [
    {
      sectionTitle: t('AutoAffordPage.subTitle', {
        defaultValue: 'What price car can I afford?',
      }),
      richBody: {
        nodeType: 'document',
        data: {},
        content: [
          {
            nodeType: 'paragraph',
            content: [
              {
                nodeType: 'text',
                marks: [],
                value: t('AutoAffordPage.textContent', {
                  defaultValue:
                    'If you have an idea of how much you want to pay upfront and monthly for your next ride, this car payment calculator is for you. Fill in the blanks to get an idea of your budget for your car search.',
                }),
                data: {},
              },
            ],
            data: {},
          },
        ],
      },
    },
  ]

  return (
    <StaticTemplate pageContext={pageContext}>
      {({ navMenus }) => (
        <Layout
          location={location}
          pageContext={{ ...pageContext, pathname: autoPage.urlPath }}
          menu={{ ...navMenus, subnav: [calculatorsPage] }}
          showSubnav
        >
          <PageMeta
            language={pageContext.langKey}
            title={t('AutoAffordPage.pageTitle', {
              defaultValue: 'Auto Affordability Calculator',
            })}
            pathname="/calculators/auto/how-much-can-afford"
          />
          <CalculatorSectionModule
            pageTitle={t('AutoAffordPage.title', {
              defaultValue: 'Auto Calculators',
            })}
            sections={pageData}
            calculator={<AutoAffordCalculator />}
            anchorLinkSections={anchorLinkSections()}
          />
          <CrossSellTout />
        </Layout>
      )}
    </StaticTemplate>
  )
}

AutoAffordPage.propTypes = {
  children: PropTypes.node,
  location: PropTypes.object,
  pageContext: PropTypes.object,
}

export { AutoAffordPage }
export default AutoAffordPage
